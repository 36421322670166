import NextHead from 'next/head';
import Router from 'next/router';
import { useEffect } from 'react';
import { general } from '../config/general';

const gaTrackingId = general.gaid;

const linkClicked = e => {
  if (!window.ga) {
    return;
  }
  const a =
    e.currentTarget.nodeName === 'A'
      ? e.currentTarget
      : e.currentTarget.querySelector('a');
  ga('send', 'event', {
    eventCategory: 'Link',
    eventAction: 'Click',
    eventLabel: `${a.innerText} - ${a.getAttribute('href')}`
  });
};

export const LinkTracker = () => {
  useEffect(() => {
    [].slice
      .call(document.querySelectorAll('[ga-track-link]'))
      .forEach(link => {
        link.addEventListener('click', linkClicked);
      });

    return () => {
      [].slice
        .call(document.querySelectorAll('[ga-track-link]'))
        .forEach(link => {
          link.removeEventListener('click', linkClicked);
        });
    };
  }, []);

  return null;
};

// https://philipwalton.com/articles/the-google-analytics-setup-i-use-on-every-site-i-build/
export const trackError = (errorEvent, fieldsObj = {}) => {
  if (
    errorEvent.message &&
    errorEvent.message == 'ResizeObserver loop limit exceeded'
  ) {
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    return;
  }

  let error = errorEvent.error ? errorEvent.error : errorEvent.message;

  ga(
    'send',
    'event',
    Object.assign(
      {
        eventCategory: 'Script',
        eventAction: 'error',
        eventLabel: (error && error.stack) || '(not set)',
        nonInteraction: true
      },
      fieldsObj
    )
  );
  console.error('Tracking error:', error, fieldsObj);
};

export const AutoTracker = () => {
  /* useEffect(() => {
    const loadErrorEvents = (window.__e && window.__e.q) || [];
    const fieldsObj = { eventAction: 'uncaught error' };

    // Replay any stored load error events.
    for (let event of loadErrorEvents) {
      trackError(event, fieldsObj);
    }

    const trackErrorListener = event => {
      trackError(event, fieldsObj);
    };

    // Add a new listener to track event immediately.
    window.addEventListener('error', trackErrorListener);

    // Remove to avoid leaks
    return () => {
      window.removeEventListener('error', trackErrorListener);
      window.__e.q = null;
    };
  }, []); */

  // Add Scripts using NextHead
  return (
    <NextHead>
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-H5J8Z2DYVE"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)};
            gtag('js', new Date());
            gtag('config', 'G-H5J8Z2DYVE');
          `
        }}
      />

      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
				window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
				ga('create', '${gaTrackingId}', 'auto');
		    ga('set', 'transport', 'beacon');

		    ga('require', 'outboundLinkTracker');
		    ga('require', 'urlChangeTracker');
		    ga('require', 'maxScrollTracker');
		    ga('require', 'pageVisibilityTracker');
		    ga('require', 'cleanUrlTracker');

		    ga('send', 'pageview');
			`,
        }}
      />
    </NextHead>
  );
};

export default AutoTracker;
