import classNames from 'classnames';
import React, { Fragment } from 'react';
import ActiveLink from '../lib/activeLink';

import AnimateIn from './AnimateIn';
import StoryblokEditableComment from './storyblok/StoryblokEditableComment';

const CardList = ({ cards = [], externalUrls = false }) => (
  <AnimateIn className="card-list">
    <ul className="u-no-list u-cf">
      {cards.map(card => <React.Fragment key={card._uid}>
        {''}{card._editable && <StoryblokEditableComment text={card._editable} />}
        <li
          className={classNames('card', {
            'card--link': card.link.url || card.link.cached_url,
            'card--gradient': !(card.link.url || card.link.cached_url)
          })}
        >
          {(card.link.url || card.link.cached_url) && (
            <ActiveLink href={card.link.url || card.link.cached_url}>
              <a
                className="card__inner"
                target={externalUrls ? '_blank' : '_self'}
              >
                <h3>{card.title}</h3>
                <div
                  className="card__description t-mini"
                  dangerouslySetInnerHTML={{ __html: card.description }}
                />
              </a>
            </ActiveLink>
          )}
          {!(card.link.url || card.link.cached_url) && (
            <div className="card__inner">
              <h3>{card.title}</h3>
              <div
                className="card__description t-mini"
                dangerouslySetInnerHTML={{ __html: card.description }}
              />
            </div>
          )}
        </li>
      </React.Fragment>)}
    </ul>
  </AnimateIn>
);

export default CardList;
