import { general } from '../config/general';
import LinkTag from './LinkTag';
const Footer = () => (
  <footer className="footer">
    <div className="footer__links">
      <div className="g-row">
        {general.offices.map(office => (
          <div className="footer__col" key={office.location}>
            <h5 className="footer__title">{office.location}</h5>
            <nav>
              <ul className="u-no-list">
                <li ga-track-link="true">
                  <LinkTag options={office.contact} />
                </li>
                <li ga-track-link="true">
                  <LinkTag options={office.address} />
                </li>
              </ul>
            </nav>
          </div>
        ))}

        <div className="footer__col">
          <h5 className="footer__title">Connect</h5>
          <nav>
            <ul className="u-no-list">
              {general.contact.map(link => (
                <li ga-track-link="true" key={link.href}>
                  <LinkTag options={link} />
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
