import classNames from 'classnames';
import { useEffect, useState } from 'react';

const ToggleButton = ({
  isActive,
  onToggle,
  expand,
  collapse,
  eventLabel,
  className
}) => {
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (window.ga && isDirty) {
      ga('send', 'event', {
        eventCategory: 'Toggle Trigger',
        eventAction: isActive ? 'Expand' : 'Collapse',
        eventLabel: eventLabel
      });
    }

    setIsDirty(true);
  }, [isActive]);

  return (
    <button
      expand-label={expand}
      className={classNames('btn', 'btn--colored', 'btn--arrow', className, {
        'btn--expanded': isActive
      })}
      collapse-label={collapse}
      event-label={eventLabel}
      onClick={onToggle}
    >
      {isActive ? collapse : expand}
    </button>
  );
};

export default ToggleButton;
