import { useEffect, useRef, useState } from 'react';

const ExpandWrapper = ({ isExpanded, children }) => {
  const outerEl = useRef(null);
  const innerEl = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const newHeight = isExpanded ? innerEl.current.clientHeight : 0;
    setHeight(newHeight);
  });

  return (
    <div
      className="toggle-content"
      expanded={isExpanded.toString()}
      ref={outerEl}
      style={{ height: height }}
    >
      <div className="toggle-content__inner" ref={innerEl}>
        {children}
      </div>
    </div>
  );
};

export default ExpandWrapper;
