export default function formatImageUrl(url, format = {}) {
  if (!url) return;

  let width = format.width || '';
  let height = format.height || '';

  if (url.indexOf('storyblok') > -1) {
    return url.replace(
      /(https:|http:)?\/\/a.storyblok.com/,
      `https://img2.storyblok.com/${width}x${height}`
    );
  } else {
    let formatString = '?w=' + width;
    return url + formatString;
  }
}
