const LinkTag = ({ options }) => (
  <a
    href={options.href}
    className={options.class}
    target={options.external ? '_blank' : undefined}
    rel={options.external ? 'noopener' : undefined}
  >
    {options.title}
  </a>
);

export default LinkTag;
