const general = {
  site: 'Your Majesty Co.',
  title: 'Your Majesty Co.',
  description: 'Strategy-led design and technology for every interface.',
  image: 'your-majesty-og.jpg',
  ogType: 'website',
  path: 'https://yourmajesty.co',
  fbAppId: '182960358893229',
  fbAuthor: 'https://www.facebook.com/yourmajestyco',
  author: 'Your Majesty Co.',
  twitter: '@yourmajestyco',
  gaid: 'UA-48102664-3',
  offices: [
    {
      location: 'Amsterdam',
      contact: {
        title: 'Business Inquiries',
        href: 'mailto:ams@yourmajesty.co',
        class: 'tertiary-link',
      },
      address: {
        title: 'Map',
        href: 'http://goo.gl/RKEQRV',
        class: 'tertiary-link',
        external: true,
      },
    },
    /*{
      "location": "London",
      "contact": {
        "title": "Business Inquiries",
        "href": "mailto:london@yourmajesty.co",
        "class": "tertiary-link"
      },
      "address": {
        "title": "Map",
        "href": "https://goo.gl/maps/jKUdUEMhvet",
        "class": "tertiary-link",
        "external": true
      }
    },*/
    {
      location: 'New York',
      contact: {
        title: 'Business Inquiries',
        href: 'mailto:nyc@yourmajesty.co',
        class: 'tertiary-link',
      },
      address: {
        title: 'Map',
        href: 'http://goo.gl/1tr4U',
        class: 'tertiary-link',
        external: true,
      },
    },
  ],
  contact: [
    {
      title: 'Twitter',
      href: 'https://twitter.com/yourmajestyco',
      class: 'tertiary-link',
    },
    {
      title: 'Instagram',
      href: 'https://instagram.com/yourmajestyco',
      class: 'tertiary-link',
    },
    {
      title: 'Newsletter',
      href: 'https://yourmajesty.co/news/newsletter',
      class: 'tertiary-link',
    },
  ],
  social: [
    {
      title: 'Twitter',
      href: 'https://twitter.com/yourmajestyco',
      class: 'tertiary-link',
      external: true,
    },
    {
      title: 'Instagram',
      href: 'https://instagram.com/yourmajestyco',
      class: 'tertiary-link',
      external: true,
    },
    {
      title: 'Medium',
      href: 'https://medium.com/your-majesty-co',
      class: 'tertiary-link',
      external: true,
    },
    {
      title: 'Newsletter',
      href: 'https://mailchi.mp/yourmajesty/newsletter',
      class: 'tertiary-link',
      external: true,
    },
  ],
};

const hallmarksList = [
  
  /* Gradient cards. Projects without links to cases. Shown first in the hallmark list. */

  /*{ id: 'lakers',
    title: 'The Los Angeles Lakers',
    description: 'Digital product agency of record.',
    modifier: 'card--gradient'
  }, */

  { id: 'fila',
    title: 'FILA',
    url: '/work/fila',
    description: 'Helping the iconic street brand launch its new collection.',
    metaDescription: 'Helping the iconic street brand launch its new global collection.',
    tagline: 'FILA Explore',
    tags: ['Campaign', 'Website'],
    image: '/fila/fila-explore-Image-01.jpg'
  },

  { id: 'awal',
    title: 'AWAL',
    description: 'Rewriting the rules of the music industry to let artists succeed on their terms.',
    modifier: 'card--gradient'
  },

  /* Featured projects are not shown in the hallmarks list. */

  { id: 'the-fabricant',
    title: 'The Fabricant',
    url: '/work/the-fabricant',
    description: 'Introducing the world’s first digital-only fashion platform.',
    metaDescription: 'Introducing LEELA — the world’s first digital-only fashion platform.',
    tagline: 'LEELA',
    tags: ['Strategy', 'Design', 'Technology'],
    image: '/fabricant/Leela-Case-Study-00-cover.png'
  },
  
  { id: 'airbnb',
    title: 'Airbnb', 
    url: '/work/airbnb', 
    description: 'Creative, design, and content to introduce a brand new guest experience.',
    tagline: 'Airbnb Plus',
    tags: ['Content', 'Website'],
  },

  { id: 'dopper',
    title: 'Dopper',
    url: '/work/dopper',
    description: 'A digital flagship store that champions our oceans.',
    metaDescription: 'A digital flagship store that champions our oceans.',
    tagline: 'Digital Flagship Store',
    tags: ['Content', 'Website'],
    image: '//media.yourmajesty.co/20190926152415/00_190926_Dopper_Cover.png'
  },

  { id: 'samsung-cl',
    title: 'Samsung', 
    url: '/work/samsung-cl', 
    description: 'Smart connections for easy living.',
    metaDescription: 'Retail installation about smart connections for easy living.',
    tagline: 'Connected Living',
    tags: ['Content', 'Installation'],
    image: '//media.yourmajesty.co/20190405095356/190122_Samsung_eod.jpg'
  },

  { id: 'cabiner',
    title: 'Cabiner',
    url: '/work/cabiner',
    description: 'A brand identity that longs for nature.',
    tagline: 'Identity Design',
    tags: ['Branding', 'Content'],
    image: '/cabiner/190517_Cabiner_LinkedIn.png'
  }/*,  

  { id: 'netflix',
    title: 'Netflix', 
    url: '/work/netflix', 
    description: 'A brand platform helping 4,700 Netflix employees and partners tell the same story globally.',
    tagline: 'Brand Platform',
    tags: ['Website'],
    image: '//media.yourmajesty.co/20170508013922/netflix-slide1.jpg'
  }*/,
  { id: 'adidas',
    title: 'adidas', 
    url: '/work/adidas', 
    description: 'Nine connected LED cubes all around Europe led to one of adidas’ most successful shoe launches.',
    tagline: 'NMD Cubes',
    tags: ['App', 'Campaign', 'Installation'],
    image: '//media.yourmajesty.co/20170509173414/large_cover.jpg'
  },

  { id: 'spotify',
    title: 'Spotify', 
    url: '/work/spotify', 
    description: 'A platform for fans to visualize their love for their favorite artists, audiobooks, or podcasts.',
    tagline: 'Data Stories',
    tags: ['Content', 'Website'],
    image: '//media.yourmajesty.co/20170724162216/01-Intro2.jpg'
  },

  /* Standard cards. Projects with link to case. */
  
  { id: 'fashion-for-good',
    title: 'Fashion For Good',
    url: '/work/fashion-for-good',
    description: 'A platform that changes fashion for good.',
    tagline: 'Website & Experiential',
    tags: ['Strategy', 'Design', 'Technology'],
    image: '/ffg/0-ffg-Hero.jpg'
  },
  
  { id: 'va-dundee',
    title: 'V&A Dundee',
    url: '/work/va-dundee',
    description: 'New frontiers for the world’s largest arts and design museum.',
    tagline: 'Digital Flagship',
    tags: ['Website'],
    image: '//media.yourmajesty.co/20180626084850/180620_VandA_Case_WebHeader.jpg'
  },

  { id: 'samsung',
    title: 'Samsung SmartThings',
    url: '/work/samsung',
    description: 'Add a little smartness to your things.',
    metaDescription: 'Add a little smartness to your things.',
    tagline: 'Brand & Marketing',
    tags: ['Website', 'Content', 'Branding'],
    image: '//media.yourmajesty.co/20170511065719/smartthings-brand-hero-image.jpg'
  },

  { id: 'i-t-hong-kong',
    title: 'I.T Hong Kong',
    url: '/work/i-t-hong-kong',
    description: 'An interactive exhibition to celebrate 30 years of luxury fashion.',
    tagline: 'Pop-up Exhibition',
    tags: ['Installation'],
    image: '//media.yourmajesty.co/20190429121640/3.jpg'
  },

  { id: 'onzeauto',
    title: 'OnzeAuto',
    url: '/work/onzeauto',
    description: 'Designing an electric car-sharing company from scratch.',
    tagline: 'Service Design',
    tags: ['App', 'Branding', 'Content', 'Website'],
    image: '//media.yourmajesty.co/20180226123600/180115-oa-case_cover.png'
  },

  { id: 'mgm-resorts',
    title: 'MGM Resorts',
    url: '/work/mgm-resorts',
    description: 'Building a digital stage for universal love.',
    tagline: 'Universal Love',
    tags: ['Campaign', 'Website'],
    image: '//media.yourmajesty.co/20180615120529/52.png'
  },

  { id: 'volkshotel',
    title: 'Volkshotel',
    url: '/work/volkshotel',
    description: 'Establishing Amsterdam’s favorite boutique hotel, resulting in a 21% increase in booked rooms.',
    tagline: 'Volkshotel.nl',
    tags: ['Content', 'Website'],
    image: '//media.yourmajesty.co/20170503205726/01b.jpg'
  },

  { id: 'bandstand-newcastle-helix',
    title: 'Newcastle Helix',
    url: '/work/bandstand-newcastle-helix',
    description: 'A digital flagship and generative brand identity for Newcastle’s new innovation center.',
    metaDescription: 'Using real-time data to generate a living brand identity.',
    tagline: 'Newcastlehelix.com',
    tags: ['Installation', 'Website'],
    image: '//media.yourmajesty.co/20190205094629/helix-share1.png'
  },

  { id: 'rottefella',
    title: 'Rottefella', 
    url: '/work/rottefella', 
    description: 'Launching a new product line for the company that defined cross country skiing.',
    tagline: 'Workout Apparel',
    tags: ['Campaign', 'Content'],
    image: '//media.yourmajesty.co/20171112214732/1.jpg'
  },

  { id: 'bentley',
    title: 'Bentley', 
    url: '/work/bentley', 
    description: 'A magical commissioning experience turning 1.3 billion combinations into the perfect Bentley for every guest.',
    tagline: 'Bentley Studio',
    tags: ['App', 'Installation'],
    image: '//media.yourmajesty.co/20170508042456/bentley-header.jpg'
  },

  { id: 'tele2',
    title: 'Tele2',
    url: '/work/tele2',
    description: 'An out-of-body product launch experience to inspire 10 million Swedes about the limitless connectivity of the Internet.',
    tagline: 'Inside a stranger’s mind',
    tags: ['Campaign', 'Content', 'Website'],
    image: '//media.yourmajesty.co/20170503234513/tele2_case_header_001.jpg'
  },

  { id: 'universal-music-group',
    title: 'Universal Music Group',
    url: '/work/universal-music-group',
    description: 'Reimagining music gifting with a content platform for super fans.',
    tagline: 'Access All Areas',
    tags: ['Website'],
    image: '//media.yourmajesty.co/20170413192123/cover.jpg'
  },

  { id: 'newmotion',
    title: 'NewMotion',
    url: '/work/newmotion',
    description: 'Advancing Europe’s leading EV charging solutions provider to the B2B sector.',
    tagline: 'Digital Agency of Record',
    tags: ['Insights & Strategy', 'Website'],
    image: 'media.yourmajesty.co/20190318160727/1920x1080-sharecard.png'
  },

  { id: 'drone-racing-league',
    title: 'Drone Racing League',
    url: '/work/drone-racing-league',
    description: 'Getting the world’s next racing sport ready for takeoff.',
    tagline: 'Digital Flagship',
    tags: ['Branding', 'Website'],
    image: '//media.yourmajesty.co/20170804144549/Introduction_alt1.jpg'
  },  

  { id: 'bmw',
    title: 'BMW', 
    url: '/work/bmw', 
    description: 'Future-proofing the BMW North America online experience, powered by AEM.',
    tagline: 'BMWUSA.com',
    tags: ['Website'],
    image: '//media.yourmajesty.co/20170506014807/bmw-og.jpg'
  },

  { id: 'conde-nast',
    title: 'Condé Nast',
    url: '/work/conde-nast',
    description: 'Redesign for the premier publisher’s new brand presence.',
    tagline: 'CondeNast.com',
    tags: ['Website'],
    image: '//media.yourmajesty.co/20170505230912/cn-slide4.jpg'
  },

  { id: 'nyt',
    title: 'The New York Times',
    url: '/work/nyt',
    description: 'Creating a media conference hub.',
    tagline: 'NYT Conferences',
    tags: ['Website'],
    image: '//media.yourmajesty.co/20170510000805/slide2.jpg'
  },

  { id: 'american-express',
    title: 'American Express',
    url: '/work/american-express',
    description: 'At the US Open, we transformed 14,000 tennis fans amateur swings into works of art.',
    tagline: 'Amex Fan Experience',
    tags: ['App', 'Installation'],
    image: '//media.yourmajesty.co/20170503195502/usopen-1.jpg'
  },

  { id: 'red-bull',
    title: 'Red Bull', 
    url: '/work/red-bull', 
    description: 'Positioning radio in a streaming era.',
    tagline: 'North America Launch',
    tags: ['Branding', 'Campaign'],
    image: '//media.yourmajesty.co/20170503200141/rbmaradio.jpg'
  },

  { id: 'thakoon',
    title: 'Thakoon',
    url: '/work/thakoon',
    description: 'Bringing everyday originality directly to consumers with a 24 releases per year eCommerce approach.',
    tagline: 'Digital Flagship',
    tags: ['Website'],
    image: '//media.yourmajesty.co/20170508182610/thakoon.jpg'
  },

  { id: 'nature-valley',
    title: 'Nature Valley',
    url: '/work/nature-valley',
    description: 'Bringing the national parks to people—digitally, resulting in 38.4 million media impressions and 34 awards.',
    tagline: 'Trail View Platform',
    tags: ['Content', 'Website'],
    image: '//media.yourmajesty.co/20170509005157/slide2-og.jpg'
  },

  { id: 'toms-guide',
    title: 'Tom’s Guide',
    url: '/work/toms-guide',
    description: 'Helping shoppers make smart decisions by anticipating their needs.',
    tagline: 'Redesign',
    tags: ['Website'],
    image: '//media.yourmajesty.co/20170510163621/toms-header.jpg'
  },

  { id: 'gilt-groupe',
    title: 'Gilt Groupe',
    url: '/work/gilt-groupe',
    description: 'A unified eCommerce platform for the famed lifestyle brand, leading to sold out sales.',
    tagline: 'Redesign',
    tags: ['Branding', 'Website'],
    image: '//media.yourmajesty.co/20170510163317/header-gilt.jpg'
  },

  { id: 'harvard-art-museums',
    title: 'Harvard Art Museums',
    url: '/work/harvard-art-museums',
    description: 'Bringing <em>Index Magazine</em> from print to digital platform.',
    metaDescription: 'Bringing Index Magazine from print to digital platform.',
    tagline: 'Index Magazine',
    tags: ['Website'],
    image: '//media.yourmajesty.co/20170510010013/header-harvard2.jpg'
  },

  { id: 'google',
    title: 'Google',
    url: '/work/google',
    description: 'B2B made simple, resulting in a +617% increase in business trials.',
    tagline: 'Google Small Business Redesign',
    tags: ['Website'],
    image: '//yourmajesty.co/assets/your-majesty-og.002703e1ad3f113c5eb3a327bc1f18d1.png'
  },/*

  { id: 'template',
    title: 'Template Project', 
    url: '/work/template', 
    description: 'This is a template project',
    tagline: 'Just an empty template',
    tags: ['Content', 'Website'],
  },  

  { id: 'emptyish',
    title: 'Template Project', 
    url: '/work/emptyish', 
    description: 'This is a template project',
    tagline: 'Just an empty template',
    tags: ['Content', 'Website'],
  }, */
  
]

const getProject = ( id ) => {
  return hallmarksList.filter( project => project.id == id )[0]
}

const pageRevalidationTime = 600;

export {
  general,
  hallmarksList,
  getProject,
  pageRevalidationTime
}
