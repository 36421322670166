import Link from 'next/link';
import { withRouter } from 'next/router';
import { Children } from 'react';

const externalRegExp = /^https?:/;

// https://gist.github.com/remy/0dde38897d6d660f0b63867c2344fb59
// For this version activeClassName should be set
const ActiveLink = ({ router, children, ...props }) => {
  const child = Children.only(children);

  let className = child.props.className || '';
  // Only internal links should use the <Link> markup
  if( !externalRegExp.test( props.href ) ) {

    if (router.asPath === props.href && props.activeClassName) {
      className = `${className} ${props.activeClassName}`.trim();
    } else {
      className = `${className}`.trim();      
    }

    delete props.activeClassName;
     
    return <Link {...props}>{React.cloneElement(child, { className })}</Link>;

  }
  
  // Not an internal link so add the href to it
  return React.cloneElement(child, { className: className, href: props.href })

};

/*
// Or this simpler version that just adds active
const ActiveLink = withRouter(({ router, children, ...props }) => (
  <Link {...props}>
    {React.cloneElement(Children.only(children), {
      className: router.pathname === props.href ? `active` : null
    })}
  </Link>
));
*/

export default withRouter(ActiveLink);
