import classNames from 'classnames';
import React, { Fragment } from 'react';
import Link from 'next/link';
import ToggleButton from './ToggleButton';
import CardList from './CardList';
import useToggle from '../lib/hooks/useToggle';
import ExpandWrapper from './ExpandWrapper';
import StoryblokEditableComment from './storyblok/StoryblokEditableComment';

const externalRegExp = /^https?:/;

const FeaturedProjects = ({ featuredProjectsList, condensed, transition }) => {
  const [isActive, onToggle] = useToggle(false);

  const { featured, hallmarks, ventures } = featuredProjectsList.content;

  return (
    <div
      className={classNames('featured-projects', {
        'featured-projects--condensed': condensed,
        component: transition,
      })}
      id={condensed ? 'menu' : ''}
    >
      <div className="featured-projects__inner g-row">
        <div
          className={classNames('featured-projects__ymco', {
            ['featured-projects__ymco--hidden']: condensed,
          })}
        >
          Your Majesty Co.
        </div>
        <h1 className="featured-projects__title">
          Design and technology that powers digital product and brand
          experiences for{' '}
          {featured.map((project, index) => (
            <React.Fragment key={project._uid}>
              {project._editable && (
                <StoryblokEditableComment text={project._editable} />
              )}
              <span>
                {!externalRegExp.test(
                  project.link.url || project.link.cached_url
                ) ? (
                  <Link href={`/${project.link.url || project.link.cached_url}`}>
                    <a
                      className={classNames(
                        'featured-projects__item',
                        'primary-link'
                      )}
                    >
                      {project.title}
                    </a>
                  </Link>
                ) : (
                  <a
                    className={classNames(
                      'featured-projects__item',
                      'primary-link'
                    )}
                    href={project.link.url || project.link.cached_url}
                    target="_blank"
                  >
                    {project.title}
                  </a>
                )}
                {index < featured.length - 2 ? ', ' : null}
                {index === featured.length - 2 ? ' and ' : null}
                {index === featured.length - 1 ? '. ' : null}
              </span>
            </React.Fragment>
          ))}
          <ToggleButton
            expand={'View all'}
            collapse={'Hide all'}
            eventLabel={'Featured Projects'}
            isActive={isActive}
            onToggle={onToggle}
          />
        </h1>

        <ExpandWrapper isExpanded={isActive}>
          {hallmarks.length > 0 && <h2>Hallmarks</h2>}
          <CardList cards={hallmarks} />
          {ventures.length > 0 && <h2>Insights & Opinions</h2>}
          <CardList cards={ventures} externalUrls={true} />
        </ExpandWrapper>

        <nav className="featured-projects__nav">
          <ul className="u-no-list u-cf">
            <li className="featured-projects__nav-item ">
              <Link href="/expertise">
                <a className="tertiary-link">Expertise</a>
              </Link>
            </li>
            <li className="featured-projects__nav-item">
              <Link href="/company">
                <a className="tertiary-link">Company</a>
              </Link>
            </li>
            <li className="featured-projects__nav-item">
              <Link href="/careers">
                <a className="tertiary-link">Careers</a>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default FeaturedProjects;
