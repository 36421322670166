import { useEffect, useState } from 'react';
import Link from 'next/link';

const HomeButton = () => {
  const [isShowing, setIsShowing] = useState(false);

  /**
   * Add event listeners
   */
  useEffect(() => {
    const updateVisibility = () => {
      if (location.pathname === '/') return setIsShowing(false);
      const top = window.scrollY;
      if (
        top > 1 &&
        top + window.innerHeight < document.body.offsetHeight - 1000
      ) {
        setIsShowing(true);
      } else {
        setIsShowing(false);
      }
    };

    window.addEventListener('scroll', updateVisibility);
    return () => {
      window.removeEventListener('scroll', updateVisibility);
    };
  }, []);

  function trackHomeButtonClick() {
    if (window.ga) {
      ga('send', 'event', {
        eventCategory: 'Home',
        eventAction: 'Click'
      });
    }
  }

  return (
    <Link href="/">
      <a
        className={`home-btn ${isShowing ? 'home-btn--visible' : ''}`}
        onClick={() => trackHomeButtonClick()}
      >
        <div className={'icon icon-logo-helmet'}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 81">
            <g
              fillRule="evenodd"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M34 47.8L26.6 38M34 47.8l7.4-9.7M29 40h10M34 47.8l-8.7 8.8M34 47.8l8.7 8.8M41.4 38l-3 14.2M26.6 38l3 14.2M24 53.2l1.3 3.4M44 53.2l-1.3 3.4M24 53.2l4.2-7.6M39.8 45.6l4 7.6M34 61.2l6.6-6.7M34 61.2l-6.6-6.7M41.4 38l3-7.4M44.4 30.6L34 28M34 28l-10.5 2.6M23.5 30.6l3 7.5M23.5 30.6L19 23.4M19 23.4l10 6M29 29.3L34 18M44.4 30.6l4.6-7.2M49 23.4l-10 6M39 29.2L34 18" />
            </g>
          </svg>
        </div>
        <div
          className={'icon icon-x icon-hover'}
          dangerouslySetInnerHTML={{
            __html: require('../public/static/svg/x.svg?include')
          }}
        />
      </a>
    </Link>
  );
};
export default HomeButton;
