import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';

const AnimateIn = ({ children, className }) => {
  const [ref, inView] = useInView({
    threshold: 0.04,
    triggerOnce: true
  });
  return (
    <div
      className={classNames('component', className, {
        ['component--visible']: inView
      })}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default AnimateIn;