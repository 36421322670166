// Get an image from the static folders, or use imgix if live
import absoluteUrl from './absoluteUrl';
import delve from 'dlv';

export default function staticOrImgix(path) {

  // regex for checking if string starts with //, http: or https:
  let isUrlRegEx = /^(\/\/|http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  // If we are a image from the old site, use the legacy path
  if (path.indexOf('//images.yourmajesty.co') > -1) {
    return path.replace('images.yourmajesty.co', 'yourmajestyco.imgix.net');
  }
  
  // if match then assume that the image has a complete url and we don't wanna do nada
  if (path.match(isUrlRegEx)) {
    return path;
  }

  const isReviewLink = () => {
    if (typeof window !== 'undefined') {
      return delve(window, 'location.host', '').indexOf('now.sh' > -1);
    }
    return false;
  };

  if (process.env.NODE_ENV === 'development' || isReviewLink()) {
    let newPath = '/static/images' + (path.charAt(0) == '/' ? '' : '/') + path;
    return absoluteUrl() + newPath;
  }

  return 'https://ym.imgix.net/' + path;
}
