import NextHead from 'next/head';
import { general } from '../config/general';
import { parse as parseUrl, resolve as resolveUrl } from 'url';
import { withRouter } from 'next/router';

import ReactHtmlParser from 'react-html-parser';

import absoluteUrl from './absoluteUrl';

import staticOrImgix from './staticOrImgix';
import formatImageUrl from './image-format';

const tag = require('html-tag');

function getLocalOrGlobalProp(prop, ctx) {
  return (ctx.content && ctx.content[prop]) || ctx[prop] || general[prop];
}

function cleanTitle(string) {
  return string.replace('&#038;', '&');
}

function metaTitle(ctx) {
  // use either local title, local name (from storyblok) or site title
  if (ctx.title)
    return `${cleanTitle(ctx.title)} - ${ctx.site || general.site}`;
  if (ctx.name) return `${cleanTitle(ctx.name)} - ${ctx.site || general.site}`;
  return general.title;
}

const MetaTags = ({ router, metaData }) => {
  let meta = metaData.data || metaData || [];

  // we don't want to modify the orignal url as that will change the results of our hallmarksListFiltered in FeaturedProjects.js
  let theUrl = [];

  // MUST RESOLVE FULL URL
  if (meta.url && meta.url === 'auto') {
    theUrl.url = router.pathname;
  }

  if (meta.url && meta.url.charAt(0) == '/') {
    theUrl.url = absoluteUrl() + meta.url;
  }

  const tags = [];

  // title
  const title = metaTitle(meta);
  if (title) {
    tags.push({ property: 'og:title', content: title, group: 'facebook' });
    tags.push({ name: 'twitter:title', content: title, group: 'twitter' });
    tags.push({ itemprop: 'name', content: title, group: 'microdata' });
  }

  // description
  const description = meta.metaDescription
    ? meta.metaDescription
    : getLocalOrGlobalProp('description', meta);
  if (description) {
    tags.push({ name: 'description', content: description, group: 'standard' });
    tags.push({
      itemprop: 'description',
      content: description,
      group: 'microdata',
    }); // eslint-disable-line
    tags.push({
      property: 'og:description',
      content: description,
      group: 'facebook',
    }); // eslint-disable-line
  }

  // image
  // old site used assets manifest and
  // const image = getImage(ctx)
  let image = staticOrImgix(getLocalOrGlobalProp('image', meta));
  image = formatImageUrl(image, { width: 1200, height: 630 });

  if (image) {
    tags.push({ property: 'og:image', content: image, group: 'facebook' });
    tags.push({ name: 'twitter:image', content: image, group: 'twitter' });
    tags.push({ itemprop: 'image', content: image, group: 'microdata' });
  }

  // author
  const author = getLocalOrGlobalProp('author', meta);
  if (author) {
    tags.push({ name: 'author', content: author, group: 'standard' });
    tags.push({
      name: 'copyright',
      content: `(c) ${new Date().getFullYear()} ${author}`,
      group: 'standard',
    }); // eslint-disable-line max-len
  }

  // site
  const site = getLocalOrGlobalProp('site', meta);
  if (site) {
    tags.push({ property: 'og:site_name', content: site, group: 'facebook' });
  }

  // type
  const type = getLocalOrGlobalProp('ogType', meta);
  if (type) {
    tags.push({ property: 'og:type', content: type, group: 'facebook' });
  }

  // url
  const url = getLocalOrGlobalProp('url', theUrl);
  if (url) {
    tags.push({ property: 'og:url', content: url, group: 'facebook' });
    tags.push({ name: 'twitter:url', content: url, group: 'twitter' });
    tags.push({ rel: 'canonical', href: url, group: 'standard', tag: 'link' });
    tags.push({
      name: 'twitter:domain',
      content: parseUrl(url).hostname,
      group: 'twitter',
    }); // eslint-disable-line max-len
  }

  // FB app id
  const fbAppId = getLocalOrGlobalProp('fbAppId', meta);
  if (fbAppId) {
    tags.push({ property: 'fb:app_id', content: fbAppId, group: 'facebook' });
  }

  // FB Author
  const fbAuthor = getLocalOrGlobalProp('fbAuthor', meta);
  if (fbAuthor && type === 'article') {
    tags.push({ property: 'og:author', content: fbAuthor, group: 'facebook' });
  }

  // Twitter
  const twitter = getLocalOrGlobalProp('twitter', meta);
  if (twitter) {
    tags.push({ name: 'twitter:creator', content: twitter, group: 'twitter' });
    tags.push({ name: 'twitter:site', content: twitter, group: 'twitter' });
  }

  // static
  tags.push({
    name: 'twitter:card',
    content: 'summary_large_image',
    group: 'twitter',
  }); // eslint-disable-line

  // divide into groups for nicer rendering
  const groups = {};
  tags.forEach((tagObj) => {
    if (typeof groups[tagObj.group] === 'undefined') {
      groups[tagObj.group] = [];
    }
    groups[tagObj.group].push(tagObj);
  });

  // create the meta tags... returns a string that's why we use ReactHtmlParser below
  const metaTags = Object.keys(groups)
    .filter((key) => groups.hasOwnProperty(key) && Array.isArray(groups[key]))
    .map((key) => {
      return groups[key]
        .map((tagObj) => {
          // sanitize attrs
          const attrs = {};
          Object.keys(tagObj)
            .filter((prop) => tagObj.hasOwnProperty(prop))
            .forEach((prop) => {
              if (['tag', 'group'].indexOf(prop) === -1) {
                attrs[prop] = tagObj[prop];
              }
            });
          // return tag
          return tag(tagObj.tag || 'meta', attrs).replace(/.$/, '/>');
        })
        .join('\n\t');
    })
    .join('\n\n\t');

  return (
    <NextHead>
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="/static/favicon.ico"
      />
      <meta name="theme-color" media="(prefers-color-scheme: light)" content="#ffffff" />
      <meta name="theme-color" media="(prefers-color-scheme: dark)" content="#151515" />
      <title>{title}</title>
      {ReactHtmlParser(metaTags)}
    </NextHead>
  );
};

export default withRouter(MetaTags);
