import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';

import Layzr from 'layzr.js';

import MetaTags from '../lib/MetaTags';

import FeaturedProjects from '../components/FeaturedProjects';
import Footer from '../components/Footer';

import HomeButton from './HomeButton';

import NextHead from 'next/head';
import StoryblokService from '../lib/storyblok-service';

import { AutoTracker, LinkTracker } from '../lib/analytics';

function Layout({
  router,
  children,
  useDarkTheme = false,
  hideFeatured = false,
  metaData = false,
  featuredProjectsList = false
}) {
  const [theme, setTheme] = useState(useDarkTheme ? 'dark' : null);

  // Should we make layzr a component instead...?
  let layzrInstance = false;

  useEffect(() => {
    layzrInstance = Layzr({
      threshold: 50
    });

    layzrInstance.on('src:before', image => {
      image.addEventListener('load', function loadImage() {
        image.classList.remove('lazy');
        image.removeEventListener('load', loadImage);
      });
    });

    // eeeh. expose to window so we can do .update and .check in component Image.js when that component is created dynamically
    window.layzr = layzrInstance;

    // needed or else it may try to fetch images that doesn't have a source yet
    // TODO: Listen for DOMContentLoaded instead
    setTimeout(() => {
      layzrInstance
        .update()
        .check()
        .handlers(true);
    }, 10);

    StoryblokService.initEditor();
  }, []);

  // https://gist.github.com/kitze/23d82bb9eb0baabfd03a6a720b1d637f
  const ConditionalWrap = ({ condition, wrap, children }) =>
    condition ? wrap(children) : children;

  return (
    <React.Fragment>
      <NextHead>
        <script
          dangerouslySetInnerHTML={{
            __html: `var StoryblokCacheVersion = '${StoryblokService.getCacheVersion()}';`,
          }}
        ></script>
      </NextHead>

      <MetaTags metaData={metaData} />

      <AutoTracker />

      <LinkTracker />

      <main
        role="main"
        className={classNames('outer', {
          ['theme--dark']: theme === 'dark',
        })}
      >
        <div className="inner">
          {children}

          {
            <ConditionalWrap
              condition={useDarkTheme}
              wrap={(children) => (
                <InView
                  rootMargin={'-100px'}
                  as="div"
                  onChange={(inView) => {
                    setTheme(inView ? null : 'dark');
                  }}
                >
                  {children}
                </InView>
              )}
            >
              {!hideFeatured && (
                <FeaturedProjects
                  featuredProjectsList={featuredProjectsList}
                  condensed={true}
                />
              )}
              <Footer />
            </ConditionalWrap>
          }

          <HomeButton />
        </div>

        {StoryblokService.bridge()}

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              var _dcq = _dcq || []; var _dcs = _dcs || {}; _dcs.account = '8144271';
              {(function () {
                var dc = document.createElement('script');
                dc.type = 'text/javascript';
                dc.async = true;
                dc.src = '//tag.getdrip.com/8144271.js';
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(dc, s);
              })()}
            `,
          }}
        />
      </main>
    </React.Fragment>
  );
}

export default Layout;
