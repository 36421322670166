const absoluteUrl = (context = {}) => {
  if (typeof window === 'undefined' && typeof context.req === 'undefined') {
    return;
  }

  let protocol = 'https://';
  let url = context.req
    ? context.req.headers['x-forwarded-host']
    : window.location.hostname;

  let port = context.req ? '' : ':' + window.location.port;

  if (url.indexOf('localhost') > -1) protocol = 'http://';

  return protocol + url + port;
};
export default absoluteUrl;
