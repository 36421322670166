import React, { useEffect, useRef } from 'react';

const StoryblokEditableComment = ( props ) => {
  const el = useRef();
  const text = props.text
    .replace('<!--', '')
    .replace('-->', '')

  useEffect(() => {
    el.current.outerHTML = `<!-- ${text} -->`;
  }, []);

  return <div ref={el}/>
}

export default StoryblokEditableComment;